









































import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default defineComponent({
  name: 'UOttawaPostLab3DiscussionPart2',
  components: {AiLoadingOverlay, StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part3k: null,
        part3l: null,
        part3m: null,
        part3n: null,
        part3o: null,
        part3p: null,
        part3q: null,
        part3r: null,
        part3s: null,
        part3t: null,
        language: 'en',
      },
      questions: [
        {
          en: 'k) What is the pH? Is it what you expect the pH of water to be? If not, what does this tell you? Why might this pH be different than the one you expect?',
          fr: 'k) Quel est le pH de l’eau? Est-ce le pH prévu pour l’eau? Si non, qu’est-ce que ceci vous dit? Pourquoi est-ce que les deux pH peuvent être autres que prévus?',
          inputName: 'part3k',
        },
        {
          en: 'l) What pH do you observe? What reaction could cause this pH to be observed? What ions are responsible for the pH change?',
          fr: 'l) Quel pH avez-vous observé? Quelle réaction peut être responsable pour ce pH? Quels ions sont responsables pour le changement du pH?',
          inputName: 'part3l',
        },
        {
          en: 'm) Why do we add the acid? What has been created by the addition of the acid to the solution of hydrogen carbonate ion?',
          fr: 'm) Pourquoi ajoutez-vous l’acide? Qu’avez-vous créé par l’ajout de l’acide à la solution contenant l’ion bicarbonate?',
          inputName: 'part3m',
        },
        {
          en: 'n) What did you observe when the acid was added to the hydrogen carbonate ion? Why did you observe this? What is the pH? How does it compare to the pH of distilled water? How does it compare to the pH of blood? What species are present in the solution? Is there more of one species than another? Is this what you want based on the theory?',
          fr: 'n) Qu’avez-vous observé quand l’acide a été ajouté à l’ion bicarbonate? Qu’est-ce qui est responsable pour cette observation? Quel est le pH? Comment est-il comparé au pH de l’eau distillée? Comment est-il comparé au pH du sang? Quelles sont les espèces dans la solution? Est-ce qu’il y a plus d’une espèce qu’une autre? Est-ce que c’est le résultat que vous prévoyez?',
          inputName: 'part3n',
        },
        {
          en: 'o) What is the pH of the solution? What condition in the body does this situation simulate? What did you observe? Write a chemical reaction to describe the process that occurred and the observations you made.',
          fr: 'o) Quel est le pH de la solution? Quelle condition du corps est simulée par cette situation? Qu’est-ce que vous avez observé? Écrivez une équation chimique qui décrit le processus et les observations que vous avez faites.',
          inputName: 'part3o',
        },
        {
          en: 'p) What do you observe? Do your observations match the information given to you? What happens to the pH of the solution with vigorous stirring? Is this what you expect? Justify your observations using a chemical reaction and equilibrium.',
          fr: 'p) Qu’est-ce que vous avez observé? Est-ce que vos observations sont en accord avec l’information donnée? En agitant rapidement, qu’est-ce qui arrive au pH de la solution? Justifiez vos observations à partir d’une réaction chimique et l’idée d’équilibre chimique.',
          inputName: 'part3p',
        },
        {
          en: 'q) What is the pH of the solution? What do you observe? Explain your observations using chemical reactions and equilibrium arguments.',
          fr: 'q) Quel est le pH de la solution? Qu’est-ce que vous avez observé? Expliquez vos observations à partir des réactions chimiques et de l’équilibre.',
          inputName: 'part3q',
        },
        {
          en: 'r) What is the pH of the solution? What do you observe? Explain your observations using chemical reactions and equilibrium arguments. What is the condition of the body at this point?',
          fr: 'r) Quel est le pH de la solution? Qu’est-ce que vous avez observé? Expliquez vos observations à partir des réactions chimiques et de l’équilibre. Quelle est la condition du corps en ce moment?',
          inputName: 'part3r',
        },
        {
          en: 's) What is the pH of the solution? What do you observe? What happens to the dry ice? Explain your observations using chemical reactions and equilibrium arguments.',
          fr: 's) Quel est le pH de la solution? Qu’est-ce que vous avez observé? Qu’est-ce qui est arrivé à la neige carbonique (CO2)? Expliquez vos observations à partir des réactions chimiques et de l’équilibre.',
          inputName: 'part3s',
        },
        {
          en: 't) What is the pH of the solution? What do you observe? Explain your observations using chemical reactions and equilibrium arguments.',
          fr: 't) Quel est le pH de la solution? Qu’est-ce que vous avez observé? Expliquez vos observations à partir des réactions chimiques et de l’équilibre.',
          inputName: 'part3t',
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
